<template>

    <div class="cakeshop-profile">

        <div class="mt-5">

            <el-row type="flex" align="middle">
                <el-col :span="8">
                </el-col>
                <el-col :span="8" class="text-center"><span class="uppercase-title">{{cakeshop.name}}</span></el-col>
                <el-col :span="8" class="text-right">
                    <div v-show="role === 'ADMIN'">
                        <span><svg-icon class="mr-1" icon-class="start"/>{{cakeshop.rate}}</span>
                        <span class="ml-4"><svg-icon class="mr-1" icon-class="phone"/>{{cakeshop.phone}}</span>
                        <span class="ml-4"><svg-icon class="mr-1" icon-class="location"/>{{cakeshop.city.name}}</span>
                    </div>
                </el-col>
            </el-row>

        </div>

        <div class="mt-4 pt-4 pb-4 px-5 bg-white border border-gray rounded">

            <el-row class="mt-1" type="flex" align="middle">

                <el-col :span="8">
                    <div class="breadcrumbs" @click="goBack">
                        <svg-icon class="mr-1" icon-class="back-arrow"/>
                        Volver a listado de Direcciones
                    </div>
                </el-col>

                <el-col :span="10" class="text-center">

                </el-col>

                <el-col :span="8" class="d-flex flex-row justify-content-end">
                    <template v-if="editing">
                        <div>
                            <el-button type="text" :disabled="loading" @click="editing = false">
                                Cancelar
                            </el-button>
                        </div>

                        <div class="ml-2">
                            <el-button type="success" :loading="loading" :disabled="loading" @click="requestCakeshopAddress">
                                Guardar
                            </el-button>
                        </div>
                    </template>

                    <template v-else>
                        <div class="ml-2">
                            <el-button :disabled="!cakeshopAddress.uuid" @click="editing = true">
                                Editar Dirección
                            </el-button>
                        </div>
                    </template>
                </el-col>

            </el-row>

            <el-row class="mt-4">
                <el-col>

                    <cakeshop-address-form
                        v-if="cakeshopAddress.uuid && editing"
                        :do-form-submit="doFormSubmit"
                        :initial-address="cakeshopAddressForm"
                        @submit="saveCakeshopAddress"
                        @not-valid="resetRequest"/>

                    <cakeshop-address-detail v-else :cakeshop-id="this.$route.params.cakeshopId" :cakeshop-address="cakeshopAddress" @deleted="goBack()"/>

                </el-col>
            </el-row>

        </div>

    </div>

</template>

<script>
    import {messages} from '../../../constants/text-constants';
    import {mapGetters} from 'vuex';
    import CakeshopAddressForm from "../../../components/CakeshopAddressForm/index";
    import {updateCakeshopAddress} from '@/api/cakeshop-address-api';
    import CakeshopAddressDetail from "./components/CakeshopAddressDetail";

    export default {
        name: "CakeProfile",
        components: {
            CakeshopAddressDetail,
            CakeshopAddressForm,
        },
        data() {
            return {
                editing: false,
                cakeshop: {
                    city: {}
                },
                cakeshopAddress: {},
                doFormSubmit: false,
                loading: false,
            };
        },
        created() {
            this.loadCakeshop();
            this.loadCakeshopAddress();
        },
        computed: {
            ...mapGetters([
                'cakeshopById',
                'role',
            ]),
            cakeshopAddressForm() {
                return {
                    address: this.cakeshopAddress.address,
                    postalCode: this.cakeshopAddress.postalCode,
                    city: this.cakeshopAddress.city,
                    zone: this.cakeshopAddress.zone,
                    phone: this.cakeshopAddress.phone,
                    openDays: this.cakeshopAddress.openDays,
                    openHours: this.cakeshopAddress.openHours,
                    contactName: this.cakeshopAddress.contactName,
                    contactPhone: this.cakeshopAddress.contactPhone,
                }
            },
        },
        methods: {
            loadCakeshop() {
                this.cakeshop = Object.assign({}, this.cakeshopById(this.$route.params.cakeshopId));
            },
            loadCakeshopAddress() {
                this.cakeshopAddress = this.$route.params.cakeshopAddress;
            },
            goBack() {
                this.$router.push({
                    name: 'cakeshop-profile',
                    params: {uuid: this.$route.params.cakeshopId, cakeshop: this.cakeshop, tab: '3'}
                });
            },
            resetRequest() {
                this.doFormSubmit = false;
                this.loading = false;
            },
            requestCakeshopAddress() {
                this.doFormSubmit = true;
                this.loading = true;
            },
            saveCakeshopAddress(cakeshopAddress) {
                let request = {
                    "address": cakeshopAddress.address,
                    "postalCode": cakeshopAddress.postalCode,
                    "cityId": cakeshopAddress.city.uuid,
                    "zone": cakeshopAddress.zone,
                    "phone": cakeshopAddress.phone,
                    "openDays": cakeshopAddress.openDays,
                    "openHours": cakeshopAddress.openHours,
                    "contactName": cakeshopAddress.contactName,
                    "contactPhone": cakeshopAddress.contactPhone,
                };

                updateCakeshopAddress(this.$route.params.cakeshopId, this.cakeshopAddress.uuid, request)
                    .then((response) => {
                        this.cakeshopAddress = Object.assign({}, response);
                        this.editing = false;
                    })
                    .catch(() => {
                        this.$message.error(messages.cakeshopAddress.update.error);
                    })
                    .finally(() => {
                        this.resetRequest();
                    });
            },
        },
    }
</script>
