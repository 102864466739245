<template>
    <div v-loading="loading">

        <el-row :gutter="20">

            <el-col :span="8">
                <el-row>
                    <el-col>
                        <div class="section-title">
                            DIRECCIÓN COMPLETA
                        </div>
                    </el-col>
                </el-row>

                <el-row class="mt-2">
                    <el-col>
                        <label>
                            Dirección
                        </label>
                        <div>{{cakeshopAddress.address}}</div>
                    </el-col>
                </el-row>

                <el-row class="mt-4">
                    <el-col>
                        <label>
                            Código Postal
                        </label>
                        <div>{{cakeshopAddress.postalCode}}</div>
                    </el-col>
                </el-row>

                <el-row class="mt-4">
                    <el-col>
                        <label>
                            Ciudad
                        </label>
                        <div>{{cakeshopAddress.city.name}}</div>
                    </el-col>
                </el-row>

                <el-row class="mt-4">
                    <el-col>
                        <label>
                            Zona
                        </label>
                        <div>{{cakeshopAddress.zone}}</div>
                    </el-col>
                </el-row>

                <el-row class="mt-4">
                    <el-col>
                        <label>
                            Teléfono
                        </label>
                        <div>{{cakeshopAddress.phone}}</div>
                    </el-col>
                </el-row>

            </el-col>

            <el-col :span="8">
                <el-row>
                    <el-col>
                        <div class="section-title">
                            HORARIOS
                        </div>
                    </el-col>
                </el-row>

                <el-row class="mt-2">
                    <el-col>
                        <label>
                            Dias de apertura
                        </label>
                        <div>{{cakeshopAddress.openDays}}</div>
                    </el-col>
                </el-row>

                <el-row class="mt-4">
                    <el-col>
                        <label>
                            Horas de apertura
                        </label>
                        <div>{{cakeshopAddress.openHours}}</div>
                    </el-col>
                </el-row>

            </el-col>

            <el-col :span="8">
                <el-row>
                    <el-col>
                        <div class="section-title">
                            PERSONA RESPONSABLE
                        </div>
                    </el-col>
                </el-row>

                <el-row class="mt-2">
                    <el-col>
                        <label>
                            Nombre
                        </label>
                        <div>{{cakeshopAddress.contactName}}</div>
                    </el-col>
                </el-row>

                <el-row class="mt-4">
                    <el-col>
                        <label>
                            Móvil
                        </label>
                        <div>{{cakeshopAddress.contactPhone}}</div>
                    </el-col>
                </el-row>

                <el-row class="mt-5 py-1" type="flex" align="middle">
                    <el-col>
                        <el-switch
                            class=""
                            :value="cakeshopAddress.available"
                            @change="changeAddressAvailability"/>
                        <span v-if="cakeshopAddress.available" class="pt-1 ml-2">Dirección disponible</span>
                        <span v-else class="pt-1 ml-2">Dirección no disponible</span>
                    </el-col>
                </el-row>

                <el-row v-show="role === 'ADMIN'" class="mt-3">
                    <el-col class="cursor-pointer">
                        <div @click="confirmDeleteCakeshopAddress">
                            <svg-icon icon-class="delete"/>
                            Eliminar dirección
                        </div>
                    </el-col>
                </el-row>

            </el-col>

        </el-row>

    </div>
</template>

<script>
    import {messages, dialogs} from '../../../../constants/text-constants';
    import {changeCakeshopAddressAvailability, deleteCakeshopAddress} from '@/api/cakeshop-address-api';
    import {mapGetters} from 'vuex';

    export default {
        name: "CakeshopAddressDetail",
        props: {
            cakeshopAddress: Object,
            cakeshopId: String
        },
        data() {
            return {
                loading: false
            };
        },
        computed: {
            ...mapGetters([
                'role',
            ]),
        },
        methods: {
            changeAddressAvailability(available) {
                this.$confirm(dialogs.cakeshopAddress.changeAvailability.text, {
                    confirmButtonText: dialogs.cakeshopAddress.changeAvailability.confirm,
                    cancelButtonText: dialogs.cakeshopAddress.changeAvailability.cancel,
                    dangerouslyUseHTMLString: true,
                }).then(() => {
                    this.loading = true;
                    changeCakeshopAddressAvailability(this.cakeshopId, this.cakeshopAddress.uuid, available)
                        .then(() => {
                            this.cakeshopAddress.available = available;
                            this.$message.success(messages.cakeshopAddress.status.success);
                        }).catch(() => {
                            this.$message.success(messages.cakeshopAddress.status.error);
                        }).finally(() => {
                            this.loading = false;
                        });
                });
            },
            confirmDeleteCakeshopAddress() {
                this.$confirm(dialogs.cakeshopAddress.cancel.text, {
                    confirmButtonText: dialogs.cakeshopAddress.cancel.confirm,
                    cancelButtonText: dialogs.cakeshopAddress.cancel.cancel,
                    dangerouslyUseHTMLString: true,
                }).then(() => {
                    this.deleteCakeshopAddress();
                });
            },
            deleteCakeshopAddress() {
                this.loading = true;
                deleteCakeshopAddress(this.cakeshopId, this.cakeshopAddress.uuid)
                .then(() => {
                    this.$message.success(messages.cakeshopAddress.delete.success);
                    this.$emit('deleted');
                }).catch(() => {
                    this.$message.error(messages.cakeshopAddress.delete.error);
                }).finally(() => this.loading = false);
            },
        }
    }
</script>

<style scoped lang="scss">
    .cursor-pointer :hover {
        font-weight: bold;

        .svg-icon {
            width: 1.2em;
            height: 1.2em;
        }
    }
</style>
